import React from 'react';
import { 
  Button, 
  Container, 
  Typography, 
  Box,
  AppBar,
  Toolbar
} from '@mui/material';

import { useEffect, useState } from 'react';

import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import Chatbot from './ChatBot';
import ParameterBot from './ParameterBot';
import SalesBot from './SalesBot';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MainContent() {
  const query = useQuery();
  const params = {};

  query.forEach((value, key) => {
    params[key] = value;
  });

  const [isChatVisible, setIsChatVisible] = useState(true);
  const[isParameterVisible, setIsParameterVisible] = useState(false);
  const [isSalesVisible, setIsSalesVisible] = useState(false);

  function showBot() {
    if ("show_bot" in params) {
      if (params["show_bot"] === "parameter") {
        setIsSalesVisible(false);
        setIsChatVisible(false);
        setIsParameterVisible(true);
      } else if (params["show_bot"] === "sales") {
        setIsChatVisible(false);
        setIsSalesVisible(true);
        setIsParameterVisible(false);
      }else{
        setIsParameterVisible(false);
        setIsChatVisible(true);
        setIsSalesVisible(false);
      }
    }
  }

  useEffect(() => {
    showBot();
  }
  , []);

  return (
    <Container>
      {/* <Button onClick={() => setIsParameterVisible(true)}>Open Parameter Bot</Button> */}
      {/* <ParameterBot isVisible={isParameterVisible} onClose={() => setIsParameterVisible(false)} /> */}
      <ParameterBot onClose={() => console.log("Close")} isVisible={isParameterVisible} />

      {/* <Button onClick={() => setIsChatVisible(true)}>Open Subscriber Bot</Button> */}
      {/* <Chatbot isVisible={isChatVisible} onClose={() => setIsChatVisible(false)} agent_type={"agent_type" in params ? params["agent_type"]:"betting"}/> */}
      <Chatbot isVisible={isChatVisible} onClose={() => console.log("Close")} agent_type={"agent_type" in params ? params["agent_type"]:"betting"}/>

      {/* <Button onClick={() => setIsSalesVisible(true)}>Open Sales Bot</Button> */}
      {/* <SalesBot isVisible={isSalesVisible} onClose={() => setIsSalesVisible(false)} agent_type={"agent_type" in params ? params["agent_type"]:"betting"}/> */}
      <SalesBot isVisible={isSalesVisible} onClose={() => console.log("Close")} agent_type={"agent_type" in params ? params["agent_type"]:"betting"}/>
    </Container>
  );
}

const App = () =>  {
  const [posts, setPosts] = useState([]);


  useEffect(() => {
    fetch('/')
      .then(response => response.json())
      .then(data => setPosts(data));
  }, []);

  return (
    <Router>
      <div>
        <MainContent />
      </div>
    </Router>
  );
}

export default App;
