import React, { useEffect, useState, useRef } from 'react';
import {
    TextField,
    Paper,
    Typography,
    Grid,
    Box,
    CircularProgress,
    IconButton,
    useTheme,
    CssBaseline
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const ChatContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '90vh',
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto, sans-serif'
}));

const Header = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(90deg, #1a1a1a 0%, #2d2d2d 100%)',
    padding: '8px',
    paddingBottom: '2px',
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    flexShrink: 0,
    fontFamily: 'Roboto, sans-serif'
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
    height: 'calc(90vh - 140px)',
    backgroundColor: theme.palette.grey[50],
    fontFamily: 'Roboto, sans-serif',
    WebkitOverflowScrolling: 'touch',
    scrollBehavior: 'smooth', // smooth scrolling
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: theme.palette.grey[100],
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[300],
        borderRadius: '4px',
        '&:hover': {
            background: theme.palette.grey[400],
        }
    }
}));


const MessageBubble = styled(Paper)(({ isuser, theme }) => ({
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(1.5),
    maxWidth: '75%',
    wordWrap: 'break-word',
    backgroundColor: isuser ? '#ff9800' : '#ffffff',
    color: isuser ? '#ffffff' : theme.palette.text.primary,
    borderRadius: isuser ? '16px 16px 4px 16px' : '16px 16px 16px 4px',
    boxShadow: isuser ? '0 2px 8px rgba(255,152,0,0.15)' : '0 2px 8px rgba(0,0,0,0.08)',
    border: isuser ? 'none' : `1px solid ${theme.palette.grey[100]}`,
    fontSize: '1rem',
    lineHeight: '1.5',
    fontFamily: 'Roboto, sans-serif',
    '& .markdown-content': {
        '& pre': {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1.5),
            borderRadius: '4px',
            backgroundColor: isuser ? 'rgba(255, 255, 255, 0.1)' : theme.palette.grey[100],
            fontFamily: 'Roboto Mono, monospace',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch'
        },
        '& code': {
            fontFamily: 'Roboto Mono, monospace',
            padding: '2px 4px',
            borderRadius: '3px',
            backgroundColor: isuser ? 'rgba(255, 255, 255, 0.1)' : theme.palette.grey[100],
            display: 'inline-block'
        },
        '& p': {
            margin: '8px 0',
            fontFamily: 'Roboto, sans-serif',
            '&:first-of-type': {
                marginTop: 0
            },
            '&:last-of-type': {
                marginBottom: 0
            }
        },
        '& ul, & ol': {
            margin: '8px 0',
            paddingLeft: '20px',
            fontFamily: 'Roboto, sans-serif'
        },
        '& blockquote': {
            margin: '8px 0',
            paddingLeft: '16px',
            borderLeft: `4px solid ${isuser ? 'rgba(255, 255, 255, 0.3)' : theme.palette.grey[300]}`,
            color: isuser ? 'rgba(255, 255, 255, 0.9)' : theme.palette.text.secondary,
            fontFamily: 'Roboto, sans-serif'
        },
        '& img': {
            maxWidth: '100%',
            borderRadius: '4px'
        },
        '& table': {
            borderCollapse: 'collapse',
            width: '100%',
            margin: '8px 0',
            '& th, & td': {
                border: `1px solid ${isuser ? 'rgba(255, 255, 255, 0.2)' : theme.palette.grey[300]}`,
                padding: '4px 8px'
            }
        }
    }
}));


const InputContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: 'white',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    flexShrink: 0,
    fontFamily: 'Roboto, sans-serif'
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    
}));

const MarkdownComponents = {
    code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return !inline && match ? (
            <SyntaxHighlighter
                style={materialLight}
                language={match[1]}
                PreTag="div"
                {...props}
            >
                {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
        ) : (
            <code className={className} {...props}>
                {children}
            </code>
        );
    }
};

const SalesBot = ({ isVisible, onClose, agent_type }) => {
    const theme = useTheme();
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const decoder = new TextDecoder();
    const messageContainerRef = useRef(null);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        setSessionIdAndStartConversation();
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages, currentMessage]);

    const setSessionIdAndStartConversation = async () => {
        try {
            setIsLoading(true);
            const sessionResponse = await fetch("/start_sales_session/", {
                method: "GET",
                credentials: "include",
            });
            const sessionData = await sessionResponse.json();
            console.log("Session ID:", sessionData);
            localStorage.setItem("session_id", sessionData.session_id);

            setMessages(prevMessages => [...prevMessages, 
                { text: "Hello! I am Max, your sales agent. How can I help you today?", from: "bot" }
            ]);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const sendMessage = async () => {
        if (!input.trim()) return;
    
        try {
            setIsLoading(true);
            const userMessage = input;
            setInput("");
    
            setMessages(prevMessages => [...prevMessages, 
                { text: userMessage, from: "user" }
            ]);
    
            const response = await fetch("/query_sales/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    query: userMessage,
                    thread_id: localStorage.getItem("session_id"),
                    agent_type: agent_type,
                }),
            });
    
            let combinedStr = "";
            const reader = response.body.getReader();
            let done = false;
    
            while (!done) {
                const { value, done: streamDone } = await reader.read();
                done = streamDone;
    
                if (value) {
                    const chunkStr = decoder.decode(value, { stream: true });
                    combinedStr += chunkStr;
                    setCurrentMessage(combinedStr);
                }
            }
    
            setMessages(prevMessages => [...prevMessages, 
                { text: combinedStr, from: "bot" }
            ]);
            setCurrentMessage("");
        } catch (error) {
            console.error("Fetch error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <CssBaseline />
            <ChatContainer>
                <Header>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Box
                                component="img"
                                src="/images/max_image.jpeg"
                                alt="Max"
                                sx={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: '16px',
                                    border: '3px solid rgba(255,255,255,0.25)',
                                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                    marginLeft: '-8px',
                                    marginTop: '-8px',
                                    marginBottom: '-8px'
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: 'white',
                                    fontWeight: 700,
                                    fontSize: '1.5rem',
                                    letterSpacing: '0.2px',
                                    lineHeight: '1.2',
                                    marginBottom: '4px',
                                    fontFamily: 'Roboto, sans-serif'
                                }}
                            >
                                Max
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    color: 'rgba(255,255,255,0.7)',
                                    fontSize: '1rem',
                                    marginTop: '2px',
                                    fontFamily: 'Roboto, sans-serif'
                                }}
                            >
                                Sales Agent
                            </Typography>
                        </Grid>
                    </Grid>
                </Header>

                <MessageContainer ref={messageContainerRef}>
                    {messages.map((msg, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: msg.from === "user" ? 'flex-end' : 'flex-start',
                                mb: 1.5
                            }}
                        >
                            <MessageBubble isuser={(msg.from==="user")?"user":""}>
                                <div className="markdown-content">
                                    <ReactMarkdown
                                        components={MarkdownComponents}
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]}
                                    >
                                        {msg.text}
                                    </ReactMarkdown>
                                </div>
                            </MessageBubble>
                        </Box>
                    ))}
                    {currentMessage && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 1.5 }}>
                            <MessageBubble>
                                <div className="markdown-content">
                                    <ReactMarkdown
                                        components={MarkdownComponents}
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]}
                                    >
                                        {currentMessage}
                                    </ReactMarkdown>
                                </div>
                            </MessageBubble>
                        </Box>
                    )}
                    {isLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                            <CircularProgress
                                size={24}
                                thickness={4}
                                sx={{ color: '#ff9800' }}
                            />
                        </Box>
                    )}
                    <div ref={messagesEndRef} />
                </MessageContainer>

                <InputContainer>
                    <Grid container spacing={1.5} alignItems="center">
                        <Grid item xs>
                            <StyledTextField
                                fullWidth
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                                placeholder="Type your message..."
                                size="small"
                                variant="outlined"
                                disabled={isLoading}
                                multiline
                                maxRows={4}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={sendMessage}
                                disabled={!input.trim() || isLoading}
                                sx={{
                                    backgroundColor: '#ff9800',
                                    color: 'white',
                                    width: '42px',
                                    height: '42px',
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        backgroundColor: '#f57c00',
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: '#e0e0e0',
                                        color: 'rgba(0, 0, 0, 0.26)'
                                    }
                                }}
                            >
                                <SendIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </InputContainer>
            </ChatContainer>
        </>
    );
};

export default SalesBot;