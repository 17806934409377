import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { 
    Button, 
    TextField,
    Divider,
    IconButton,
    Container, 
    Paper,
    Typography, 
    Grid,
    Stack,
    Box,
    CircularProgress,
    AppBar,
    Toolbar
  } from '@mui/material';


import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@mui/system/styled';
import QuizIcon from '@mui/icons-material/Quiz';
// import { MuiMarkdown, getOverrides } from 'mui-markdown';

import './styling/chatbot_styling2.css';

const ParameterBot = ({isVisible, onClose}) => {
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");


    const [input, setInput] = useState("");
    const [instance_id, setInstanceId] = useState(0);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
  
    // Convert the query parameters to an object
    const params = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });

    const decoder = new TextDecoder();

    useEffect(() => {
        // testSendMessage();
    }, []);


      
    return (
        <div>
            <Box id="main_border" sx={{display: isVisible ? "block" : "none" , backgroundColor: 'white', borderRadius: "1REM", border: "0.2REM solid rgb(0, 0, 0)", width: "25REM"}}>
                <Grid container direction="column" sx={{justifyContent: "space-between",}}>
                    <Grid item id="top_back" xs={12} direction="row" container sx={{backgroundColor:"white", borderTopLeftRadius: "1REM", borderTopRightRadius: "1REM"}}>
                        <Grid item xs={4} p={1} pb={0} sx={{overflow:"hidden"}}>
                            <Box sx={{
                                position: "relative",
                                height: "8rem",
                                width: "8rem",
                                borderTopLeftRadius: "1rem",
                                overflow: "hidden", // Ensures the gradient doesn't overflow the image
                            }}>
                                <Box
                                    component="img"
                                    src="/images/max_image.jpeg"
                                    alt="max_image"
                                    sx={{
                                    height: "8rem",
                                    width: "8rem",
                                    borderTopLeftRadius: "1rem",
                                    objectFit: "cover",
                                    maskImage: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 44%);",
                                    WebkitMaskImage: "linear-gradient(to right, rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%)",
                                    }}
                                />
                                
                            </Box>
                        </Grid>

                        <Grid item xs={6}
                            container
                            sx={{alignItems: "center", justifyContent: "flex-end"}}
                        >
                            <div>
                                <Typography fontFamily="Arial" variant="h4" component="div" align="right" color="black">
                                    John
                                </Typography>
                                <Typography variant="body1" gutterBottom align="right" color="grey">
                                    Test Agent
                                </Typography>
                            </div>
                        </Grid>

                    </Grid>
                    <Divider/>
                    <Grid item xs={12} p={2}>
                        <div style={{ height: "25REM", overflowY: "auto" }}>
                        <div>
                            <h1>URL Parameters</h1>
                            {Object.entries(params).map(([key, value]) => (
                                <p key={key}>
                                {key}: {value}
                                </p>
                            ))}
                        </div>

                                {messages.map((msg, index) => (
                                    <div>
                                        <Box key={index} className={msg.from === "user" ? "user-message" : "bot-message"}
                                        sx={{borderRadius: "1REM"}} p={2}>
                                            {messages[index].text}
                                        </Box>
                                        <br/>
                                    </div>

                                ))}
                                <Box p={2}>
                                    {currentMessage}
                                </Box>
                        </div>
                    </Grid>           
                </Grid>
            </Box>
                    
        </div>
        
    );
};

export default ParameterBot;