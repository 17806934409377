import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Chatbot from './ChatBot';
import ParameterBot from './ParameterBot';
import SalesBot from './SalesBot';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function MainContent() {
    const query = useQuery();
    const location = useLocation(); 
    const scrollRef = useRef(null);
    
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [isParameterVisible, setIsParameterVisible] = useState(false);
    const [isSalesVisible, setIsSalesVisible] = useState(false);

    const agentType = query.get("agent_type");
    const promptParam = query.get("prompt");
    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        // Reset visibility states
        setIsChatVisible(false);
        setIsParameterVisible(false);
        setIsSalesVisible(false);
    
        // Show the correct bot based on agent type
        if (agentType === "parameter") {
            setIsParameterVisible(true);
        } else if (agentType === "sales") {
            setIsSalesVisible(true);
        } else {
            setIsChatVisible(true);
        }
        
        // Scroll to bottom whenever the bot changes
        scrollToBottom();
    }, [location.search]);

    return (
        <div style={{ height: '100vh', overflowY: 'auto' }}>
            {isParameterVisible && (
                <ParameterBot
                    onClose={() => setIsParameterVisible(false)}
                    isVisible={isParameterVisible}
                    scrollRef={scrollRef}
                />
            )}
            {isSalesVisible && (
                <SalesBot
                    agent_type={"sales"}
                    scrollRef={scrollRef}
                    prompt={promptParam ? promptParam : ""}
                />
            )}
            {isChatVisible && (
                <Chatbot
                    isVisible={isChatVisible}
                    onClose={() => setIsChatVisible(false)}
                    agent_type={agentType}
                    scrollRef={scrollRef}
                />
            )}
            <div ref={scrollRef} />
        </div>
    );
}

const App = () => {
    return (
        <Router>
            <MainContent />
        </Router>
    );
};

export default App;