import React, { useEffect, useState, useRef } from 'react';
import {
    TextField,
    Paper,
    Typography,
    Grid,
    Box,
    CircularProgress,
    IconButton,
    useTheme,
    CssBaseline,
    Dialog,
    DialogContent,
    Fade
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Apply document-level transparency for iframe compatibility
const DocumentTransparency = () => {
    useEffect(() => {
        // Make the document background transparent
        document.body.style.backgroundColor = 'transparent';
        document.documentElement.style.backgroundColor = 'transparent';
        
        // Create a style element to ensure all parent containers are transparent
        const style = document.createElement('style');
        style.innerHTML = `
            html, body, #root, #app {
                background-color: transparent !important;
            }
        `;
        document.head.appendChild(style);
        
        return () => {
            document.head.removeChild(style);
        };
    }, []);
    
    return null;
};

const ChatContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '90vh',
    backgroundColor: 'rgba(70, 80, 95, 0.5)', // 50% transparent slate blue-gray background
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15), inset 0 1px 1px rgba(255, 255, 255, 0.1)',
    overflow: 'hidden',
    border: `1px solid rgba(100, 110, 125, 0.3)`,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto, sans-serif',
    backdropFilter: 'blur(8px)' // Increased blur effect for a more glass-like appearance
}));

const Header = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(90deg, rgba(55, 65, 80, 0.9) 0%, rgba(75, 85, 100, 0.9) 100%)', // Slate blue-gray header
    padding: '8px',
    paddingBottom: '2px',
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    flexShrink: 0,
    fontFamily: 'Roboto, sans-serif'
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
    height: 'calc(90vh - 140px)',
    backgroundColor: 'rgba(65, 75, 90, 0.5)', // 50% transparent slate blue-gray background
    fontFamily: 'Roboto, sans-serif',
    WebkitOverflowScrolling: 'touch',
    scrollBehavior: 'smooth', // smooth scrolling
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: 'rgba(40, 40, 40, 0.5)',
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[700],
        borderRadius: '4px',
        '&:hover': {
            background: theme.palette.grey[600],
        }
    }
}));


const MessageBubble = styled(Paper)(({ isuser, theme }) => ({
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(1.5),
    maxWidth: '75%',
    wordWrap: 'break-word',
    backgroundColor: isuser ? 'rgba(70, 80, 95, 0.75)' : '#ffffff', // More transparent slate blue for user messages, solid white for bot
    color: isuser ? '#ffffff' : theme.palette.text.primary,
    borderRadius: isuser ? '16px 16px 4px 16px' : '16px 16px 16px 4px',
    boxShadow: isuser ? '0 2px 8px rgba(255,152,0,0.15)' : '0 2px 8px rgba(0,0,0,0.08)',
    border: isuser ? 'none' : `1px solid ${theme.palette.grey[100]}`,
    fontSize: '1rem',
    lineHeight: '1.5',
    fontFamily: 'Roboto, sans-serif',
    '& .markdown-content': {
        '& pre': {
            margin: theme.spacing(1, 0),
            padding: theme.spacing(1.5),
            borderRadius: '4px',
            backgroundColor: isuser ? 'rgba(255, 255, 255, 0.1)' : theme.palette.grey[100],
            fontFamily: 'Roboto Mono, monospace',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch'
        },
        '& code': {
            fontFamily: 'Roboto Mono, monospace',
            padding: '2px 4px',
            borderRadius: '3px',
            backgroundColor: isuser ? 'rgba(255, 255, 255, 0.1)' : theme.palette.grey[100],
            display: 'inline-block'
        },
        '& p': {
            margin: '8px 0',
            fontFamily: 'Roboto, sans-serif',
            '&:first-of-type': {
                marginTop: 0
            },
            '&:last-of-type': {
                marginBottom: 0
            }
        },
        '& ul, & ol': {
            margin: '8px 0',
            paddingLeft: '20px',
            fontFamily: 'Roboto, sans-serif'
        },
        '& blockquote': {
            margin: '8px 0',
            paddingLeft: '16px',
            borderLeft: `4px solid ${isuser ? 'rgba(255, 255, 255, 0.3)' : theme.palette.grey[300]}`,
            color: isuser ? 'rgba(255, 255, 255, 0.9)' : theme.palette.text.secondary,
            fontFamily: 'Roboto, sans-serif'
        },
        '& img': {
            maxWidth: '100%',
            borderRadius: '4px'
        },
        '& table': {
            borderCollapse: 'collapse',
            width: '100%',
            margin: '8px 0',
            '& th, & td': {
                border: `1px solid ${isuser ? 'rgba(255, 255, 255, 0.2)' : theme.palette.grey[300]}`,
                padding: '4px 8px'
            }
        }
    }
}));


const InputContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: 'rgba(75, 85, 100, 0.7)', // Slate blue-gray input area with 70% opacity
    borderTop: `1px solid ${theme.palette.grey[800]}`,
    flexShrink: 0,
    fontFamily: 'Roboto, sans-serif'
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'rgba(80, 90, 105, 0.8)', // Slate blue-gray input field
        color: 'white',
        '& fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.3)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(70, 80, 95, 1)',
        },
    },
    '& .MuiInputBase-input': {
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.7)',
            opacity: 1,
        },
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
}));

const MarkdownComponents = {
    code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return !inline && match ? (
            <SyntaxHighlighter
                style={materialLight}
                language={match[1]}
                PreTag="div"
                {...props}
            >
                {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
        ) : (
            <code className={className} {...props}>
                {children}
            </code>
        );
    }
};

const SalesBot = ({agent_type, prompt, scrollRef }) => {
    const theme = useTheme();
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showLoadingDialog, setShowLoadingDialog] = useState(false);
    const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
    const [prompt_param, setPromptParam] = useState(prompt);
    const decoder = new TextDecoder();
    const messageContainerRef = useRef(null);
    const messagesEndRef = useRef(null);
    const [session_id, setSessionId] = useState(null);
    const loadingMessageTimerRef = useRef(null);
    
    // Array of dynamic loading messages
    const loadingMessages = [
        "Maxella is crafting a response for you",
        "Analyzing your request...",
        "Searching for the best information...",
        "Connecting the dots...",
        "Thinking creatively...",
        "Processing data points...",
        "Almost there...",
        "Preparing insights for you...",
        "Optimizing your answer...",
        "Finalizing response..."
    ];

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        setSessionIdAndStartConversation();
        get_conversation_history();
    }, []);

    useEffect(() => {
        if (session_id) {
            sendPrompt();
        }
    }, [session_id]);

    useEffect(() => {
        scrollToBottom();
    }, [messages, currentMessage]);

    // Handle loading dialog display without delay
    useEffect(() => {
        if (isLoading) {
            // Show loading dialog immediately when loading starts
            setShowLoadingDialog(true);
            // Start rotating messages
            setLoadingMessageIndex(0);
            rotateLoadingMessages();
        } else {
            // Hide dialog when loading stops
            setShowLoadingDialog(false);
            // Clear message rotation timer
            if (loadingMessageTimerRef.current) {
                clearInterval(loadingMessageTimerRef.current);
            }
        }
        
        // Cleanup function
        return () => {
            if (loadingMessageTimerRef.current) {
                clearInterval(loadingMessageTimerRef.current);
            }
        };
    }, [isLoading]);
    
    // Function to rotate through loading messages
    const rotateLoadingMessages = () => {
        // Clear any existing timer
        if (loadingMessageTimerRef.current) {
            clearInterval(loadingMessageTimerRef.current);
        }
        
        // Set up a new interval to change messages every 3 seconds
        loadingMessageTimerRef.current = setInterval(() => {
            setLoadingMessageIndex(prevIndex => (prevIndex + 1) % loadingMessages.length);
        }, 3000);
    };

    // Hide dialog as soon as content starts coming in
    useEffect(() => {
        if (currentMessage && currentMessage.trim() !== "") {
            setShowLoadingDialog(false);
            // Also clear the message rotation timer
            if (loadingMessageTimerRef.current) {
                clearInterval(loadingMessageTimerRef.current);
            }
        }
    }, [currentMessage]);

    async function get_conversation_history() {
        if (localStorage.getItem("session_id")) {
            // console.log("Getting conversation history for session ID:", session_id);
            const response = await fetch("/get_conversation_history/", {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    session_id: localStorage.getItem("session_id"),
                }),
            });
            const conversationHistory = await response.json();
            // console.log("Conversation History:", conversationHistory);
            // Process conversation history starting from index 2
            if (conversationHistory.messages && conversationHistory.messages.length > 2) {
                const historicalMessages = conversationHistory.messages.slice(2).map(message => ({
                    text: message.content,
                    from: message.type === "human" ? "user" : "bot"
                }));
                setMessages(historicalMessages);
            }
        }
    }

    async function get_response(userMessage) {
        const response = await fetch("/query_sales/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/event-stream",
            },
            body: JSON.stringify({
                query: userMessage,
                thread_id: localStorage.getItem("session_id"),
                agent_type: agent_type,
            }),
        });

        let combinedStr = "";
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        
        try {
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                
                const text = decoder.decode(value);
                
                for (const chunk of text) {
                    // Hide loading dialog as soon as first chunk arrives
                    setShowLoadingDialog(false);
                    combinedStr += chunk;
                    setCurrentMessage(prevMessage => prevMessage + chunk);
                }
            }
        } catch (error) {
            console.error("Error reading stream:", error);
        }

        return combinedStr;
    }

    const setSessionIdAndStartConversation = async () => {
        try {
            setIsLoading(true);
            // Get URL parameters
            const sessionResponse = await fetch("/start_sales_session/?session_id=" + localStorage.getItem("session_id"), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
    
            const sessionData = await sessionResponse.json();
            if (sessionData.session_id) {
                console.log("Session ID:", sessionData);
                localStorage.setItem("session_id", sessionData.session_id);
                setSessionId(sessionData.session_id);
            }
            console.log(sessionData.message);
            // console.log("session_id", localStorage.getItem("session_id"));
            
        } catch (error) {
            console.error("Error:", error);

        } finally {
            setIsLoading(false);
        }
    };

    const sendPrompt = async () => {
        if (prompt_param == "") {
            setPromptParam("Introduce yourself and ask how you can assist the customer.");
        }
        const response = await fetch("/send_prompt/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                prompt: prompt_param,
                session_id: session_id,
            }),
        });

        let combinedStr = "";
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        
        try {
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                
                const text = decoder.decode(value);
                
                for (const chunk of text) {
                    // console.log("Processing chunk:", chunk);
                    combinedStr += chunk;
                    setCurrentMessage(prevMessage => prevMessage + chunk);
                }
            }
        } catch (error) {
            console.error("Error reading stream:", error);
        }

        setMessages(prevMessages => [
            { text: combinedStr, from: "bot" }
        ]);
        setCurrentMessage("");
    }
        
        

    const sendMessage = async () => {
        if (!input.trim()) return;
    
        try {
            setIsLoading(true);
            const userMessage = input;
            setInput("");
    
            setMessages(prevMessages => [...prevMessages, 
                { text: userMessage, from: "user" }
            ]);

            const combinedStr = await get_response(userMessage);
    
            setMessages(prevMessages => [...prevMessages, 
                { text: combinedStr, from: "bot" }
            ]);
            setCurrentMessage("");
        } catch (error) {
            console.error("Fetch error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <DocumentTransparency />
            <CssBaseline />
            <ChatContainer>
                <Header>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Box
                                component="img"
                                src="/images/maxella_image.jpeg"
                                alt="Maxella"
                                sx={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: '16px',
                                    border: '3px solid rgba(255,255,255,0.25)',
                                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                    marginLeft: '-8px',
                                    marginTop: '-8px',
                                    marginBottom: '-8px'
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: 'white',
                                    fontWeight: 700,
                                    fontSize: '1.5rem',
                                    letterSpacing: '0.2px',
                                    lineHeight: '1.2',
                                    marginBottom: '4px',
                                    fontFamily: 'Roboto, sans-serif'
                                }}
                            >
                                maxella
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    color: 'rgba(255,255,255,0.7)',
                                    fontSize: '1rem',
                                    marginTop: '2px',
                                    fontFamily: 'Roboto, sans-serif'
                                }}
                            >
                                Sales broker 
                            </Typography>
                        </Grid>
                    </Grid>
                </Header>

                <MessageContainer ref={messageContainerRef}>
                    {messages.map((msg, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: msg.from === "user" ? 'flex-end' : 'flex-start',
                                mb: 1.5
                            }}
                        >
                            <MessageBubble isuser={(msg.from==="user")?"user":""}>
                                <div className="markdown-content">
                                    <ReactMarkdown
                                        components={MarkdownComponents}
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]}
                                    >
                                        {msg.text}
                                    </ReactMarkdown>
                                </div>
                            </MessageBubble>
                        </Box>
                    ))}
                    {currentMessage && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 1.5 }}>
                            <MessageBubble>
                                <div className="markdown-content">
                                    <ReactMarkdown
                                        components={MarkdownComponents}
                                        remarkPlugins={[remarkGfm]}
                                        rehypePlugins={[rehypeRaw]}
                                    >
                                        {currentMessage}
                                    </ReactMarkdown>
                                </div>
                            </MessageBubble>
                        </Box>
                    )}
                    <div ref={messagesEndRef} />
                </MessageContainer>

                {/* Improved Dialog for loading state with dynamic messages */}
                <Dialog
                    open={showLoadingDialog}
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 400 }}
                    PaperProps={{
                        sx: {
                            backgroundColor: 'rgba(65, 75, 90, 0.85)',
                            backdropFilter: 'blur(10px)',
                            color: 'white',
                            borderRadius: '18px',
                            maxWidth: '320px',
                            border: '1px solid rgba(255, 255, 255, 0.1)',
                            boxShadow: '0 12px 36px rgba(0, 0, 0, 0.25), 0 4px 12px rgba(0, 0, 0, 0.15)',
                            overflow: 'hidden'
                        }
                    }}
                >
                    <DialogContent sx={{ 
                        textAlign: 'center', 
                        py: 3.5, 
                        px: 4.5,
                        background: 'linear-gradient(135deg, rgba(70, 80, 95, 0.9) 0%, rgba(60, 70, 85, 0.95) 100%)'
                    }}>
                        <Box sx={{ 
                            position: 'relative', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            mb: 2.5,
                            mt: 0.5
                        }}>
                            <CircularProgress 
                                size={46} 
                                thickness={4} 
                                sx={{ 
                                    color: '#ff9800',
                                    opacity: 0.85 
                                }} 
                            />
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Box
                                    component="img"
                                    src="/images/maxella_image.jpeg"
                                    alt="Maxella"
                                    sx={{
                                        width: 28,
                                        height: 28,
                                        borderRadius: '50%',
                                        border: '2px solid rgba(255,152,0,0.7)'
                                    }}
                                />
                            </Box>
                        </Box>
                        <Typography 
                            variant="h6" 
                            sx={{ 
                                fontWeight: 500,
                                fontFamily: 'Roboto, sans-serif',
                                mb: 1,
                                fontSize: '1.2rem',
                                letterSpacing: '0.3px'
                            }}
                        >
                            Processing request...
                        </Typography>
                        <Box sx={{ 
                            height: '48px', 
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center'
                        }}>
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: 'rgba(255, 255, 255, 0.7)',
                                    fontFamily: 'Roboto, sans-serif',
                                    fontSize: '0.95rem',
                                    fontWeight: 300,
                                    lineHeight: 1.5,
                                    transition: 'opacity 0.5s ease',
                                    opacity: 1
                                }}
                            >
                                {loadingMessages[loadingMessageIndex]}
                            </Typography>
                        </Box>
                    </DialogContent>
                </Dialog>

                <InputContainer>
                    <Grid container spacing={1.5} alignItems="center">
                        <Grid item xs>
                            <StyledTextField
                                fullWidth
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                                placeholder="Type your message..."
                                size="small"
                                variant="outlined"
                                disabled={isLoading}
                                multiline
                                maxRows={4}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={sendMessage}
                                disabled={!input.trim() || isLoading}
                                sx={{
                                    backgroundColor: 'rgba(70, 80, 95, 1)',
                                    color: 'white',
                                    width: '42px',
                                    height: '42px',
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        backgroundColor: 'rgba(60, 70, 85, 1)',
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: '#e0e0e0',
                                        color: 'rgba(0, 0, 0, 0.26)'
                                    }
                                }}
                            >
                                <SendIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </InputContainer>
            </ChatContainer>
        </>
    );
};

export default SalesBot;