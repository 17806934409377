import React, { useEffect, useState, useRef } from 'react';
import {
    TextField,
    Paper,
    Typography,
    Grid,
    Box,
    CircularProgress,
    IconButton,
    useTheme,
    ThemeProvider,
    createTheme,
    CssBaseline
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Theme configuration
const theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif'
        ].join(','),
        chatHeading: {
            fontSize: '1.5rem',
            fontWeight: 700,
            letterSpacing: '0.2px',
            lineHeight: 1.2,
        },
        chatSubtitle: {
            fontSize: '1rem',
            fontWeight: 400,
            opacity: 0.7,
        },
        messageText: {
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: 400,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: '"Roboto", sans-serif',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    chatHeading: 'h6',
                    chatSubtitle: 'subtitle1',
                    messageText: 'body1',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#ff9800',
            dark: '#f57c00',
        },
        grey: {
            50: '#f8f9fa',
            100: '#f0f2f5',
            200: '#e9ecef',
            300: '#dee2e6',
            400: '#ced4da',
        },
        background: {
            header: 'linear-gradient(90deg, #1a1a1a 0%, #2d2d2d 100%)',
            message: '#ffffff',
            userMessage: '#ff9800',
        },
    },
});

// Styled components
const ChatContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '90vh',
    backgroundColor: theme.palette.background.message,
    borderRadius: theme.spacing(2),
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.grey[200]}`,
    display: 'flex',
    flexDirection: 'column',
    ...theme.typography.messageText,
}));

const Header = styled(Box)(({ theme }) => ({
    background: theme.palette.background.header,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(0.25),
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    flexShrink: 0,
}));

const MessageContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
    height: 'calc(90vh - 140px)',
    backgroundColor: theme.palette.grey[50],
    ...theme.typography.messageText,
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-track': {
        background: theme.palette.grey[100],
        borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: theme.palette.grey[300],
        borderRadius: '4px',
        '&:hover': {
            background: theme.palette.grey[400],
        }
    },
    scrollBehavior: 'smooth' // Add smooth scrolling
}));

const MessageBubble = styled(Paper)(({ isuser, theme }) => ({
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(1.5),
    maxWidth: '75%',
    wordWrap: 'break-word',
    backgroundColor: isuser ? theme.palette.background.userMessage : theme.palette.background.message,
    color: isuser ? '#ffffff' : theme.palette.text.primary,
    borderRadius: isuser ? '16px 16px 4px 16px' : '16px 16px 16px 4px',
    boxShadow: isuser 
        ? '0 2px 8px rgba(255,152,0,0.15)' 
        : '0 2px 8px rgba(0,0,0,0.08)',
    border: isuser ? 'none' : `1px solid ${theme.palette.grey[100]}`,
    ...theme.typography.messageText,
}));

const InputContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.message,
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    flexShrink: 0,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.grey[50],
        borderRadius: '24px',
        transition: theme.transitions.create(['background-color', 'border-color']),
        '& fieldset': {
            borderColor: 'transparent',
            borderWidth: '1.5px',
        },
        '& .MuiOutlinedInput-input': {
            padding: '12px 18px',
            ...theme.typography.messageText,
        },
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
            '& fieldset': {
                borderColor: 'transparent',
            }
        },
        '&.Mui-focused': {
            backgroundColor: theme.palette.background.message,
            '& fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: '1.5px',
            }
        },
    }
}));

const MarkdownComponents = {
    code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return !inline && match ? (
            <SyntaxHighlighter
                style={materialLight}
                language={match[1]}
                PreTag="div"
                {...props}
            >
                {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
        ) : (
            <code className={className} {...props}>
                {children}
            </code>
        );
    }
};

const Chatbot = ({ isVisible, onClose, agent_type }) => {
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [input, setInput] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    const decoder = new TextDecoder();
    const messageContainerRef = useRef(null);
    const messagesEndRef = useRef(null);

    const get_opening_msg = () => {
        if(agent_type === "betting" || agent_type === "FX" || agent_type === "crypto") {
            return `Hello! I am Max, your ${agent_type} agent. Please provide your name, email, and phone number so I can register you for our ${agent_type} tips, newsletters, and news.`;
        }
        return "Hello! I am Max, your FX agent. Please provide your name, email, and phone number so I can register you for our FX tips, newsletters, and news.";
    };

    const scrollToBottom = () => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    };

    // Initial setup and opening message
    useEffect(() => {
        setSessionIdAndStartConversation();
    }, []);

    // Scroll on new messages or updates
    useEffect(() => {
        scrollToBottom();
    }, [messages, currentMessage]);

    const setSessionIdAndStartConversation = async () => {
        try {
            const opening_msg = get_opening_msg();
            setIsLoading(true);
            const sessionResponse = await fetch("/start_session/", {
                method: "POST",
                credentials: "include",
            });
            const sessionData = await sessionResponse.json();
            localStorage.setItem("session_id", sessionData.session_id);
            
            setMessages(prevMessages => [...prevMessages, 
                { text: opening_msg, from: "bot" }
            ]);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const sendMessage = async () => {
        if (!input.trim()) return;
        
        try {
            setIsLoading(true);
            const userMessage = input;
            setInput("");
            
            setMessages(prevMessages => [...prevMessages, 
                { text: userMessage, from: "user" }
            ]);

            const response = await fetch("/query/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    query: userMessage,
                    thread_id: localStorage.getItem("session_id"),
                    agent_type: agent_type
                })
            });
            
            let combinedStr = "";
            let done = false;
            const reader = response.body.getReader();
            
            while (!done) {
                const { value, done: streamDone } = await reader.read();
                done = streamDone;

                if (value) {
                    const chunkStr = decoder.decode(value, { stream: true });
                    combinedStr += chunkStr;
                    setCurrentMessage(combinedStr);
                }
            }

            setMessages(prevMessages => [...prevMessages, 
                { text: combinedStr, from: "bot" }
            ]);
            setCurrentMessage("");
        } catch (error) {
            console.error("Fetch error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ChatContainer>
                <Header>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Box
                                component="img"
                                src="/images/max_image.jpeg"
                                alt="Max"
                                sx={{
                                    width: 100,
                                    height: 100,
                                    borderRadius: '16px',
                                    border: '3px solid rgba(255,255,255,0.25)',
                                    boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                    ml: -1,
                                    mt: -1,
                                    mb: -1
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="chatHeading" color="white">
                                Max
                            </Typography>
                            <Typography variant="chatSubtitle" color="white">
                                Subscriber Agent
                            </Typography>
                        </Grid>
                    </Grid>
                </Header>

                <MessageContainer ref={messageContainerRef}>
                    {messages.map((msg, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: msg.from === "user" ? 'flex-end' : 'flex-start',
                                mb: 1.5
                            }}
                        >
                            <MessageBubble isuser={msg.from === "user" ? "user" : ""}>
                                <ReactMarkdown
                                    components={MarkdownComponents}
                                    remarkPlugins={[remarkGfm]}
                                    rehypePlugins={[rehypeRaw]}
                                >
                                    {msg.text}
                                </ReactMarkdown>
                            </MessageBubble>
                        </Box>
                    ))}
                    {currentMessage && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 1.5 }}>
                            <MessageBubble>
                                {currentMessage}
                            </MessageBubble>
                        </Box>
                    )}
                    {isLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                            <CircularProgress
                                size={24}
                                thickness={4}
                                sx={{ color: theme.palette.primary.main }}
                            />
                        </Box>
                    )}
                    <div ref={messagesEndRef} />
                </MessageContainer>

                <InputContainer>
                    <Grid container spacing={1.5} alignItems="center">
                        <Grid item xs>
                            <StyledTextField
                                fullWidth
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                                placeholder="Type your message..."
                                size="small"
                                variant="outlined"
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={sendMessage}
                                disabled={!input.trim() || isLoading}
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: 'white',
                                    width: '42px',
                                    height: '42px',
                                    transition: theme.transitions.create('background-color'),
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-disabled': {
                                        backgroundColor: theme.palette.grey[300],
                                        color: 'rgba(0, 0, 0, 0.26)'
                                    }
                                }}
                            >
                                <SendIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </InputContainer>
            </ChatContainer>
        </ThemeProvider>
    );
};

export default Chatbot;