// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `Container {
    background: #f4f4f4;
}

img {
    width: 30%;
    height: 30%;
}

Grid {
    border: 2px solid rgb(255, 255, 255); /* Outline color and thickness */
  }


html {
    font-size: 20px;
}

.user-message{
    text-align: right;
    background-color: rgb(234, 234, 234);
    width: 60%;
    margin-left: auto; 
    margin-right: 0;
    
}

.bot-message{
    text-align: left;
}

TextField {
    background-color: rgb(234, 234, 234);
}

#top_back {
    background: rgb(255,255,255);
    /* background: linear-gradient(90deg, rgba(255,255,255,1) 9%, rgba(0,0,0,1) 61%); */
    width: 100%;
    height: 100%;
}

#main_border {
    /* background: linear-gradient(27deg, rgba(34,193,195,1) 0%, rgba(216,127,227,1) 100%); */

}
`, "",{"version":3,"sources":["webpack://./src/styling/chatbot_styling2.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI,oCAAoC,EAAE,gCAAgC;EACxE;;;AAGF;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,oCAAoC;IACpC,UAAU;IACV,iBAAiB;IACjB,eAAe;;AAEnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,4BAA4B;IAC5B,mFAAmF;IACnF,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,yFAAyF;;AAE7F","sourcesContent":["Container {\n    background: #f4f4f4;\n}\n\nimg {\n    width: 30%;\n    height: 30%;\n}\n\nGrid {\n    border: 2px solid rgb(255, 255, 255); /* Outline color and thickness */\n  }\n\n\nhtml {\n    font-size: 20px;\n}\n\n.user-message{\n    text-align: right;\n    background-color: rgb(234, 234, 234);\n    width: 60%;\n    margin-left: auto; \n    margin-right: 0;\n    \n}\n\n.bot-message{\n    text-align: left;\n}\n\nTextField {\n    background-color: rgb(234, 234, 234);\n}\n\n#top_back {\n    background: rgb(255,255,255);\n    /* background: linear-gradient(90deg, rgba(255,255,255,1) 9%, rgba(0,0,0,1) 61%); */\n    width: 100%;\n    height: 100%;\n}\n\n#main_border {\n    /* background: linear-gradient(27deg, rgba(34,193,195,1) 0%, rgba(216,127,227,1) 100%); */\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
