import React, { useEffect, useState, useRef } from 'react';
import { 
    Button, 
    TextField,
    Divider,
    IconButton,
    Container, 
    Paper,
    Typography, 
    Grid,
    Stack,
    Box,
    CircularProgress,
    AppBar,
    LoadingButton,
    Toolbar
  } from '@mui/material';


import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@mui/system/styled';
import QuizIcon from '@mui/icons-material/Quiz';
// import { MuiMarkdown, getOverrides } from 'mui-markdown';

// import './styling/chatbot_styling.css';

const Chatbot = ({isVisible, onClose, agent_type}) => {
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState("");

    const [input, setInput] = useState("");


    const decoder = new TextDecoder();
    const messagesEndRef = useRef(null); // Ref for the end of the message list

    // Function to scroll to the bottom of the message list
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        setSessionIdAndStartConversation();
    }, []);

    useEffect(() => {
        scrollToBottom(); // Scroll whenever messages or currentMessage updates
    }, [messages, currentMessage]);
    
    const setSessionIdAndStartConversation = async () => {
        try {
            // First fetch call: set the session ID
            const sessionResponse = await fetch("/start_session/", {
                method: "POST",
                credentials: "include", // Ensure cookies are included
            });
            const sessionData = await sessionResponse.json();
            console.log("Session ID:", sessionData.session_id);
    
            // Store the session ID
            localStorage.setItem("session_id", sessionData.session_id);
    
            // Second fetch call: start the conversation using the session ID
            const conversationResponse = await fetch("/query/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    query: "",
                    thread_id: sessionData.session_id, // Use the session ID
                    agent_type:agent_type
                }),
            });
    
            const decoder = new TextDecoder();
            let combinedStr = "";
            for await (const chunk of conversationResponse.body) {
                const newStr = decoder.decode(chunk);
                combinedStr = combinedStr.concat(newStr);
                setCurrentMessage(combinedStr);
            }
    
            console.log("Conversation started:", combinedStr);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const sendMessage = async () => {
        try {
            setMessages([...messages, {text:currentMessage, from:"bot"},{ text: input, from: "user" }]);
            const response = await fetch("/query/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ 
                    query: input, 
                    thread_id: localStorage.getItem("session_id"),
                    agent_type:agent_type})
            });

            setInput("");
            setCurrentMessage("");

            var combined_str = "";
            for await (const chunk of response.body) {
                const new_str = decoder.decode(chunk);
                combined_str = combined_str.concat(new_str);
                setCurrentMessage(combined_str);
            }

        } catch (error) {
            console.error("Fetch error:", error);
        }
    };
      
    return (
        <div>
            <Box sx={{display: isVisible ? "block" : "none" , backgroundColor: 'white', borderRadius: "1REM", border: "0.2REM solid rgb(0, 0, 0)", width: "25REM"}}>
                <Grid container direction="column" sx={{justifyContent: "space-between",}}>
                    <Grid item xs={12} direction="row" container sx={{backgroundColor: 'black', borderTopLeftRadius: "0.5REM", borderTopRightRadius: "0.5REM"}}>
                        <Grid item xs={4} p={2} sx={{overflow:"hidden"}}>
                            <div>
                                <Box
                                    component="img"
                                    sx={{
                                        height: "6REM",
                                        width: "6REM",
                                        borderRadius: "1REM",
                                    }}
                                    alt="max_image"
                                    src="/images/max_image.jpeg"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}
                            container
                            sx={{alignItems: "center", justifyContent: "flex-start"}}
                        >
                            <div>
                                <Typography fontFamily="Arial" variant="h4" component="div" align="left" color="white">
                                    Max
                                </Typography>
                                <Typography variant="body1" gutterBottom align="left" color="grey">
                                    Subscriber Agent
                                </Typography>
                            </div>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} p={2}>
                        <Box
                            sx={{ height: "25REM", overflowY: "auto" }}
                            ref={messagesEndRef} // Attach the ref to the container of the messages
                        >
                            {messages.map((msg, index) => (
                                <Box key={index} sx={{ display: "flex", justifyContent: msg.from === "bot" ? "flex-start" : "flex-end" }}>
                                    <Paper elevation={msg.from == "bot" ? 0 : 1} sx={{borderRadius: "1REM", p:1, m:1, backgroundColor: msg.from == "bot" ? "white":"lightgrey" }}>
                                        <Typography variant="body1" gutterBottom>
                                            {msg.text}
                                        </Typography>
                                    </Paper>
                                    
                                </Box>
                            ))}
                            <Box>
                                <Paper elevation={0} sx={{borderRadius: "1REM", p:1, m:1, backgroundColor: "white"}}>
                                    {
                                        currentMessage === "" ? <CircularProgress /> : 
                                        <Typography variant="body1" gutterBottom>
                                            {currentMessage}
                                         </Typography>
                                    }
                                </Paper>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} p={2}>
                        <div>
                            <TextField value={input} 
                                onChange={(e) => setInput(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && sendMessage()} 
                                placeholder="Enter Message"
                                size="small"
                                fullWidth
                                multiline  
                                maxRows={2} 
                                InputProps={{endAdornment: <IconButton type="button" sx={{ p: '1REM' }} aria-label="search" onClick={sendMessage}>
                                                                <SendIcon sx={{color: "orange"}}/>
                                                            </IconButton>}}
                            />
                        </div>
                        
                    </Grid>
                </Grid>
            </Box>
        </div>
        
    );
};

export default Chatbot;